<template>
    <div class="mx-auto container p-4 pb-16">
        <div class="max-w-xl mx-auto">
            <loading-capsule
                :loaded="!!(lessons && lessons.length > 0)"
                spinner-margin-top="10rem">
                <lessons-grid/>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';
    import LessonsGrid from '@/components/partials/Dashboard/LessonsGrid';

    export default {
        name      : 'Dashboard',
        mounted() {
            this.resetCurrentActiveUnit();
        },
        methods   : {
            resetCurrentActiveUnit: function () {
                this.$store.commit('Unit/resetState');
            },
        },
        computed  : {
            ...mapGetters({
                lessons: 'Course/getCourseLessons',
            }),
        },
        components: {
            LessonsGrid,
            LoadingCapsule,
        },
    };
</script>

<template>
    <div>
        <active-lesson-controls v-if="lessons && lessons.length > 0"/>
        <div class="pb-16 pt-8">
            <other-lessons-list v-if="shouldShowOtherLessons"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import OtherLessonsList from '@/components/partials/Dashboard/OtherLessonsList';
    import ActiveLessonControls from '@/components/partials/Dashboard/ActiveLessonControls';

    export default {
        name      : 'LessonsGrid',
        computed  : {
            ...mapGetters({
                name   : 'Account/getName',
                lessons: 'Course/getCourseLessons',
            }),
            shouldShowOtherLessons: function () {
                return (this.lessons || []).length > 1;
            },
        },
        components: {
            ActiveLessonControls,
            OtherLessonsList,
        },
    };
</script>

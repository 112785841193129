<template>
    <div
        :class="additionalClasses"
        @click="selectUnit"
        class="unitSummaryUnitSelector unitSummaryUnitSelector__container">
        <p class="unitSummaryUnitSelector__title" v-text="title"/>
    </div>
</template>

<script>
    export default {
        name    : 'UnitSelector',
        props   : {
            title           : {
                required: true,
                type    : String,
            },
            uuid            : {
                required: true,
                type    : String,
            },
            previousUnitUuid: {
                required: false,
                type    : String,
            },
        },
        methods : {
            /**
             * Start the given unit.
             *
             * @return void
             */
            selectUnit() {
                if (this.canProceed) {
                    this.$store.dispatch('Unit/updateActiveUnitUuid', this.uuid)
                        .then(() => {
                            this.$router.push({name: 'app.learn.unit'});
                            this.$store.dispatch('Unit/fetchUnit');
                        });
                }
            },
        },
        computed: {
            additionalClasses: function () {
                if (true === this.hasSucceeded) {
                    return 'unitSummaryUnitSelector__container--completed';
                } else if (true === this.isLocked) {
                    return 'unitSummaryUnitSelector__container--locked';
                }
            },
            canProceed       : function () {
                return false === this.isLocked || this.hasSucceeded;
            },
            progress: function() {
               const progress = this.$store.getters['Progress/getUserProgress'];

               return progress.find(progress => progress.id === this.uuid)
            },
            hasSucceeded     : function () {
                const userHasPassedUnit = this.$store.getters['Progress/userHasPassedUnit'];

                return userHasPassedUnit(this.uuid);
            },
            isLocked         : function () {
                if (null === this.previousUnitUuid) {
                    return false;
                }

                const userHasPassedUnit = this.$store.getters['Progress/userHasPassedUnit'];

                return false === userHasPassedUnit(this.previousUnitUuid);
            },
        },
    };
</script>

<template>
    <div class="unitSummaryIcon" :class="classString">
        <div class="unitSummaryIcon__container">
            <img class="unitSummaryIcon__image"
                 :src="imageSrc"
                 alt="">
            <svg viewBox="0 0 32 32">
                <circle :style="circleStyle" r="16" cx="16" cy="16"/>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'LessonIcon',
        data    : function () {
            return {
                percentage: 0,
            };
        },
        props   : {
            imageSrc  : {
                type   : String,
                default: '',
            },
            completion: {
                required: false,
                default : 0,
            },
            type      : {
                type   : String,
                default: 'LG',
            },
        },
        computed: {
            circleStyle: function () {
                const percent = this.percentage > 90
                    ? this.percentage + 1
                    : this.percentage;

                return `stroke-dasharray: ${percent} 100;`;
            },
            classString: function () {
                switch (this.type) {
                    case 'xs':
                        return 'unitSummaryIcon--xs';
                    case 'sm':
                        return 'unitSummaryIcon--sm';
                    default:
                        return 'unitSummaryIcon--lg';
                }
            },
        },
        watch   : {
            completion: {
                immediate: true,
                handler  : function (value) {
                    setTimeout(() => {
                        this.percentage = value;
                    }, 100);
                },
            },
        },
    };
</script>

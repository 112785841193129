<template>
    <div>
        <div class="text-center max-w-base mx-auto">
            <p class="dashboard__chooseUnitTitle"
               v-text="$t('DASHBOARD.CHOOSE_A_UNIT')"/>
        </div>
        <div class="flex flex-wrap max-w-base mx-auto pt-6">
            <div class="w-1/2 sm:w-auto p-1 text-center cursor-pointer"
                 @click="switchLesson(lesson.id)"
                 v-for="lesson in lessons"
                 :key="lesson.id">
                <lesson-icon
                    :image-src="getImageSrcForLesson(lesson)"
                    :completion="calculateCompletionForLesson(lesson)"
                    type="sm"/>
                <p class="dashboard__chooseUnitName" v-text="getLocalisedNameForLesson(lesson)"></p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LessonIcon from '@/components/partials/Dashboard/LessonIcon';

    export default {
        name      : 'OtherLessonsList',
        computed  : {
            ...mapGetters({
                lessons     : 'Course/getCourseLessons',
                userUuid    : 'Account/getUuid',
                interfaceIso: 'Account/getInterfaceIso',
            }),
        },
        methods   : {
            /**
             * Calculate the user's progress for the given lesson.
             *
             * @param {*} lesson
             *
             * @return {number}
             */
            calculateCompletionForLesson: function (lesson) {
                const units       = lesson.units;
                const totalUnits  = units.length;
                const unitsPassed = units.reduce((count, unit) => {
                    const userHasPassedUnit = this.$store.getters['Progress/userHasPassedUnit'](unit.id);

                    return true === userHasPassedUnit
                        ? count + 1
                        : count;
                }, 0);

                return (unitsPassed / totalUnits) * 100;
            },

            /**
             * Handle switching the user's lesson (i.e. unit)
             *
             * @param uuid
             *
             * @return void
             */
            switchLesson(uuid) {
                const payload = {
                    active_lesson_uuid: uuid,
                };

                this.$store.commit('Account/updateActiveLessonUuid', uuid);
                this.$scrollTo('#app');

                this.$http
                    .patch(`api/users/${this.userUuid}`, payload)
                    .catch(error => console.error(error));
            },

            /**
             * Get the image src for the given lesson.
             *
             * @param {*} lesson
             *
             * @return {string|null}
             */
            getImageSrcForLesson(lesson) {
                return lesson.entity.hasImage()
                    ? lesson.entity.getImageSrc()
                    : null;
            },

            /**
             * Get the localised name for the lesson in the user's interface language.
             *
             * @param {*} lesson
             *
             * @return {String|*}
             */
            getLocalisedNameForLesson(lesson) {
                return lesson.entity.getTranslationFor(this.interfaceIso) || lesson.name;
            },
        },
        components: {
            LessonIcon,
        },
    };
</script>

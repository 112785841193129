<template>
    <div>
        <div class="pt-8 pb-6" v-if="activeLesson">
            <lesson-icon
                class="flex justify-center"
                :image-src="imageSrc"
                :completion="completionPercentage"/>
            <div class="text-center mx-auto w-2/3 md:w-1/3">
                <p class="dashboard__currentSummaryTitle mt-4 mb-0"
                   v-text="$t('DASHBOARD.CURRENT_UNIT_TITLE')"/>
                <p class="dashboard__currentUnitName mt-0" v-text="name"/>
            </div>
        </div>
        <div class="text-center">
            <p class="dashboard__chooseLessonTitle"
               v-text="$t('DASHBOARD.CHOOSE_A_LESSON')"/>
            <div class="unitSummaryUnitSelector max-w-base mx-auto">
                <unit-selector
                    v-for="unit in activeLesson.units"
                    :key="unit.id"
                    :uuid="unit.id"
                    :previous-unit-uuid="unit.previous_unit_uuid"
                    :title="getTranslatedNameForUnit(unit)"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LessonIcon from '@/components/partials/Dashboard/LessonIcon';
    import UnitSelector from '@/components/partials/Dashboard/UnitSelector';

    export default {
        name      : 'ActiveLessonControls',
        computed  : {
            ...mapGetters({
                activeLesson: 'Course/getActiveCourseLesson',
                interfaceIso: 'Account/getInterfaceIso',
            }),
            completionPercentage: function () {
                const units       = this.activeLesson.units;
                const totalUnits  = units.length;
                const unitsPassed = units.reduce((count, unit) => {
                    const userHasPassedUnit = this.$store.getters['Progress/userHasPassedUnit'](unit.id);

                    return true === userHasPassedUnit
                        ? count + 1
                        : count;
                }, 0);

                return (unitsPassed / totalUnits) * 100;
            },
            imageSrc            : function () {
                return this.activeLesson.entity.hasImage()
                    ? this.activeLesson.entity.getImageSrc()
                    : null;
            },
            name                : function () {
                return this.activeLesson.entity.getTranslationFor(this.interfaceIso)
                    || this.activeLesson.name;
            },
        },
        methods   : {
            /**
             * Get the translated name for the user's interface language for the given unit,
             * if available.
             *
             * @param {*} unit
             *
             * @return {String}
             */
            getTranslatedNameForUnit(unit) {
                return unit.entity.getTranslationFor(this.interfaceIso)
                    || unit.name;
            },
        },
        components: {
            LessonIcon,
            UnitSelector,
        },
    };
</script>
